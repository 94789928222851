// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/SetupEvent.css */

.setup-event-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #1c1c1c;
  color: #ffffff;
}

h1 {
  margin-bottom: 20px;
}

.time-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.time-bubble {
  background-color: #333333;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid #00ff88;
  text-align: center;
  margin: 10px;
}

.form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.form-group {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

label {
  margin-bottom: 5px;
}

input, select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #333;
  background-color: #333;
  color: #fff;
}

button {
  padding: 10px 20px;
  background-color: #00ff88;
  border: none;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  margin-top: 20px;
}

button:hover {
  background-color: #00cc70;
}
`, "",{"version":3,"sources":["webpack://./src/components/SetupEvent.css"],"names":[],"mappings":"AAAA,kCAAkC;;AAElC;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* src/components/SetupEvent.css */\n\n.setup-event-page {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 20px;\n  background-color: #1c1c1c;\n  color: #ffffff;\n}\n\nh1 {\n  margin-bottom: 20px;\n}\n\n.time-container {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  margin-bottom: 20px;\n}\n\n.time-bubble {\n  background-color: #333333;\n  padding: 20px;\n  border-radius: 10px;\n  border: 2px solid #00ff88;\n  text-align: center;\n  margin: 10px;\n}\n\n.form-container {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  width: 100%;\n}\n\n.form-group {\n  margin: 10px;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n\nlabel {\n  margin-bottom: 5px;\n}\n\ninput, select {\n  padding: 10px;\n  border-radius: 5px;\n  border: 1px solid #333;\n  background-color: #333;\n  color: #fff;\n}\n\nbutton {\n  padding: 10px 20px;\n  background-color: #00ff88;\n  border: none;\n  border-radius: 5px;\n  color: #000;\n  cursor: pointer;\n  margin-top: 20px;\n}\n\nbutton:hover {\n  background-color: #00cc70;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
