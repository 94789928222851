// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-signups-page {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.signups-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.signup-item {
  background-color: #4caf50; /* Changed to green background for the signup box */
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 200px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-details {
  margin-bottom: 20px;
  color: #ffffff; /* Changed text color to white */
}

.signup-details p {
  margin: 5px 0;
  text-align: center;
}

.button.delete-btn {
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}

.button.delete-btn:hover {
  background-color: #c0392b;
}
`, "",{"version":3,"sources":["webpack://./src/components/EventSignups.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,uBAAuB;AACzB;;AAEA;EACE,yBAAyB,EAAE,mDAAmD;EAC9E,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,wCAAwC;EACxC,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,cAAc,EAAE,gCAAgC;AAClD;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".event-signups-page {\n  padding: 20px;\n  max-width: 800px;\n  margin: 0 auto;\n}\n\nh1 {\n  text-align: center;\n  margin-bottom: 20px;\n}\n\n.signups-list {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 20px;\n  justify-content: center;\n}\n\n.signup-item {\n  background-color: #4caf50; /* Changed to green background for the signup box */\n  border: 1px solid #ddd;\n  border-radius: 8px;\n  padding: 20px;\n  width: 200px;\n  position: relative;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.signup-details {\n  margin-bottom: 20px;\n  color: #ffffff; /* Changed text color to white */\n}\n\n.signup-details p {\n  margin: 5px 0;\n  text-align: center;\n}\n\n.button.delete-btn {\n  background-color: #e74c3c;\n  color: white;\n  border: none;\n  border-radius: 50%;\n  width: 30px;\n  height: 30px;\n  font-size: 16px;\n  cursor: pointer;\n  position: absolute;\n  bottom: 10px;\n  right: 10px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  transition: background-color 0.3s;\n}\n\n.button.delete-btn:hover {\n  background-color: #c0392b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
