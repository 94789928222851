// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/AdminLogin.css */

.admin-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #181b1e; /* Page background color */
  color: #ffffff;
  padding: 20px;
}

h1 {
  color: #86ffcc; /* Match the highlight color */
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

label {
  margin-bottom: 5px;
  color: #86ffcc; /* Match the label color */
}

input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #86ffcc; /* Match the input border color */
  background-color: #23312f; /* Match the input background color */
  color: #ffffff;
  width: 300px; /* Set a reasonable width */
}

button {
  padding: 10px 20px;
  background-color: #e2bb57; /* Match the button background color */
  border: none;
  border-radius: 5px;
  color: #000000; /* Match the button text color */
  cursor: pointer;
  margin-top: 20px;
}

button:hover {
  background-color: #d1aa46; /* Slightly darker shade for hover */
}

.error {
  color: #ff0000; /* Error message color */
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminLogin.css"],"names":[],"mappings":"AAAA,kCAAkC;;AAElC;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,yBAAyB,EAAE,0BAA0B;EACrD,cAAc;EACd,aAAa;AACf;;AAEA;EACE,cAAc,EAAE,8BAA8B;EAC9C,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,cAAc,EAAE,0BAA0B;AAC5C;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,yBAAyB,EAAE,iCAAiC;EAC5D,yBAAyB,EAAE,qCAAqC;EAChE,cAAc;EACd,YAAY,EAAE,2BAA2B;AAC3C;;AAEA;EACE,kBAAkB;EAClB,yBAAyB,EAAE,sCAAsC;EACjE,YAAY;EACZ,kBAAkB;EAClB,cAAc,EAAE,gCAAgC;EAChD,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,yBAAyB,EAAE,oCAAoC;AACjE;;AAEA;EACE,cAAc,EAAE,wBAAwB;EACxC,gBAAgB;AAClB","sourcesContent":["/* src/components/AdminLogin.css */\n\n.admin-login-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  background-color: #181b1e; /* Page background color */\n  color: #ffffff;\n  padding: 20px;\n}\n\nh1 {\n  color: #86ffcc; /* Match the highlight color */\n  margin-bottom: 20px;\n}\n\n.form-group {\n  margin-bottom: 20px;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n\nlabel {\n  margin-bottom: 5px;\n  color: #86ffcc; /* Match the label color */\n}\n\ninput {\n  padding: 10px;\n  border-radius: 5px;\n  border: 1px solid #86ffcc; /* Match the input border color */\n  background-color: #23312f; /* Match the input background color */\n  color: #ffffff;\n  width: 300px; /* Set a reasonable width */\n}\n\nbutton {\n  padding: 10px 20px;\n  background-color: #e2bb57; /* Match the button background color */\n  border: none;\n  border-radius: 5px;\n  color: #000000; /* Match the button text color */\n  cursor: pointer;\n  margin-top: 20px;\n}\n\nbutton:hover {\n  background-color: #d1aa46; /* Slightly darker shade for hover */\n}\n\n.error {\n  color: #ff0000; /* Error message color */\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
