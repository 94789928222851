// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/ViewComp.css */

.view-comp-page {
  padding: 20px;
  background-color: #181b1e; /* Page background color */
  color: #ffffff;
}

h1, h2 {
  color: #86ffcc; /* Match the highlight color */
}

.party-container {
  margin-bottom: 20px;
}

.roles-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.role-square {
  padding: 10px;
  border-radius: 5px;
  width: 120px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white;
  border: 1px dashed black;
  color: black;
}

.signup-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-name {
  font-weight: bold;
}

.signup-role {
  margin-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ViewComp.css"],"names":[],"mappings":"AAAA,gCAAgC;;AAEhC;EACE,aAAa;EACb,yBAAyB,EAAE,0BAA0B;EACrD,cAAc;AAChB;;AAEA;EACE,cAAc,EAAE,8BAA8B;AAChD;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,SAAS;AACX;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,uBAAuB;EACvB,wBAAwB;EACxB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["/* src/components/ViewComp.css */\n\n.view-comp-page {\n  padding: 20px;\n  background-color: #181b1e; /* Page background color */\n  color: #ffffff;\n}\n\nh1, h2 {\n  color: #86ffcc; /* Match the highlight color */\n}\n\n.party-container {\n  margin-bottom: 20px;\n}\n\n.roles-container {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 10px;\n}\n\n.role-square {\n  padding: 10px;\n  border-radius: 5px;\n  width: 120px;\n  height: 100px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  background-color: white;\n  border: 1px dashed black;\n  color: black;\n}\n\n.signup-details {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.signup-name {\n  font-weight: bold;\n}\n\n.signup-role {\n  margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
