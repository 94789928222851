// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/AdminPanel.css */

.admin-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #181b1e; /* Page background color */
  color: #ffffff;
  height: 100vh;
  padding: 20px;
}

.admin-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

h1 {
  margin-bottom: 20px;
  color: #86ffcc; /* Match the highlight color */
}

button {
  padding: 10px 20px;
  background-color: #e2bb57 !important; /* Update button background color */
  border: none;
  border-radius: 5px;
  color: #000000 !important; /* Match button text color */
  cursor: pointer;
  margin: 10px 0;
  text-decoration: none; /* Ensure text is not underlined */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

button:hover {
  background-color: #d1aa46 !important; /* Slightly darker shade for hover */
}

button:focus {
  outline: none;
  box-shadow: 0 0 5px #86ffcc;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminPanel.css"],"names":[],"mappings":"AAAA,kCAAkC;;AAElC;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,yBAAyB,EAAE,0BAA0B;EACrD,cAAc;EACd,aAAa;EACb,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,cAAc,EAAE,8BAA8B;AAChD;;AAEA;EACE,kBAAkB;EAClB,oCAAoC,EAAE,mCAAmC;EACzE,YAAY;EACZ,kBAAkB;EAClB,yBAAyB,EAAE,4BAA4B;EACvD,eAAe;EACf,cAAc;EACd,qBAAqB,EAAE,kCAAkC;EACzD,6CAA6C,EAAE,wCAAwC;AACzF;;AAEA;EACE,oCAAoC,EAAE,oCAAoC;AAC5E;;AAEA;EACE,aAAa;EACb,2BAA2B;AAC7B","sourcesContent":["/* src/components/AdminPanel.css */\n\n.admin-panel {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: #181b1e; /* Page background color */\n  color: #ffffff;\n  height: 100vh;\n  padding: 20px;\n}\n\n.admin-content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  flex-grow: 1;\n}\n\nh1 {\n  margin-bottom: 20px;\n  color: #86ffcc; /* Match the highlight color */\n}\n\nbutton {\n  padding: 10px 20px;\n  background-color: #e2bb57 !important; /* Update button background color */\n  border: none;\n  border-radius: 5px;\n  color: #000000 !important; /* Match button text color */\n  cursor: pointer;\n  margin: 10px 0;\n  text-decoration: none; /* Ensure text is not underlined */\n  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */\n}\n\nbutton:hover {\n  background-color: #d1aa46 !important; /* Slightly darker shade for hover */\n}\n\nbutton:focus {\n  outline: none;\n  box-shadow: 0 0 5px #86ffcc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
