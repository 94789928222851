const imageList = [
    'Ancient Ent',
    'Arcane Staff',
    'Assassin Hood',
    'Assassin Jacket',
    'Assassin Shoes',
    'Astral Aegis',
    'Astral Staff',
    'Avalonian Cape',
    'Battleaxe',
    'Battle Bracers',
    'Battle Eagle',
    'Battle Rhino',
    'Bear Paws',
    'Bedrock Mace',
    'Behemoth',
    'Black Monk Stave',
    'Blazing Staff',
    'Blight Staff',
    'Bloodletter',
    'Bloodmoon Staff',
    'Boltcasters',
    'Boots of Valor',
    'Bow of Badon',
    'Bow',
    'Brawler Gloves',
    'Brecilien Cape',
    'Bridgewatch Cape',
    'Bridled Fury',
    'Brimstone Staff',
    'Broadsword',
    'Caerleon Cape',
    'Caitiff Shield',
    'Camlann Mace',
    'Cape',
    'Carrioncaller',
    'Carving Sword',
    'Celestial Censer',
    'Chillhowl',
    'Clarent Blade',
    'Claws',
    'Claymore',
    'Cleric Cowl',
    'Cleric Robe',
    'Cleric Sandals',
    'Colossus Beetle',
    'Cowl of Purity',
    'Crossbow',
    'Cryptcandle',
    'Cultist Cowl',
    'Cultist Robe',
    'Cultist Sandals',
    'Cursed Skull',
    'Cursed Staff',
    'Dagger Pair',
    'Dagger',
    'Damnation Staff',
    'Dawnsong',
    'Daybreaker',
    'Deathgivers',
    'Demon Armor',
    'Demon Boots',
    'Demon Cape',
    'Demonfang',
    'Demon Helmet',
    'Demonic Staff',
    'Divine Staff',
    'Double Bladed Staff',
    'Druid Cowl',
    'Druidic Staff',
    'Druid Robe',
    'Druid Sandals',
    'Dual Swords',
    'Duskweaver Boots',
    'Duskweaver Helmet',
    'Earthrune Staff',
    'Energy Shaper',
    'Enigmatic Staff',
    'EquipmentBackdrop',
    'Evensong',
    'Eye of Secrets',
    'Facebreaker',
    'Fallen Staff',
    'Feyscale Hat',
    'Feyscale Robe',
    'Feyscale Sandals',
    'Fiend Cowl',
    'Fiend Robe',
    'Fiend Sandals',
    'Fire Staff',
    'Fists of Avalon',
    'Forge Hammers',
    'Fort Sterling Cape',
    'Frost Staff',
    'Galatine Pair',
    'Glacial Staff',
    'Glaive',
    'Goliath Horseeater',
    'Grailseeker',
    'Graveguard Armor',
    'Graveguard Boots',
    'Graveguard Helmet',
    'Great Arcane Staff',
    'Greataxe',
    'Great Cursed Staff',
    'Great Fire Staff',
    'Great Frost Staff',
    'Great Hammer',
    'Great Holy Staff',
    'Great Nature Staff',
    'Grovekeeper',
    'Guardian Armor',
    'Guardian Boots',
    'Guardian Helmet',
    'Halberd',
    'Hallowfall',
    'Hammer',
    'Hand of Justice',
    'Harvester Backpack',
    'Harvester Cap',
    'Harvester Garb',
    'Harvester Workboots',
    'Heavy Crossbow',
    'Heavy Mace',
    'Hellfire Hands',
    'Hellion Hood',
    'Hellion Jacket',
    'Hellion Shoes',
    'Hellspawn Staff',
    'Helmet of Valor',
    'Heretic Cape',
    'Heron Spear',
    'Hoarfrost Staff',
    'Holy Staff',
    'Hood of Tenacity',
    'Hunter Hood',
    'Hunter Jacket',
    'Hunter Shoes',
    'Icicle Staff',
    'Incubus Mace',
    'Infernal Scythe',
    'Infernal Staff',
    'Infinity Blade',
    'Iron-clad Staff',
    'Ironroot Staff',
    'Jacket of Tenacity',
    'Judicator Armor',
    'Judicator Boots',
    'Judicator Helmet',
    'Juggernaut',
    'Keeper Cape',
    'Kingmaker',
    'Knight Armor',
    'Knight Boots',
    'Knight Helmet',
    'Leering Cane',
    'Lifecurse Staff',
    'Lifetouch Staff',
    'Lightcaller',
    'Light Crossbow',
    'Longbow',
    'Lumberjack Backpack',
    'Lumberjack Cap',
    'Lumberjack Garb',
    'Lumberjack Workboots',
    'Lymhurst Cape',
    'Mace',
    'Mage Cowl',
    'Mage Robe',
    'Mage Sandals',
    'Malevolent Locus',
    'Martlock Cape',
    'Mercenary Hood',
    'Mercenary Jacket',
    'Mercenary Shoes',
    'Miner Backpack',
    'Miner Cap',
    'Miner Garb',
    'Miner Workboots',
    'Mistcaller',
    'Mistpiercer',
    'Mistwalker Hood',
    'Mistwalker Jacket',
    'Morgana Cape',
    'Morning Star',
    'Muisak',
    'Nature Staff',
    'Oathkeepers',
    'Occult Staff',
    'Permafrost Prism',
    'Phalanx Beetle',
    'Pike',
    'Polehammer',
    'Primal Staff',
    'Prowling Staff',
    'Quarrier Backpack',
    'Quarrier Cap',
    'Quarrier Garb',
    'Quarrier Workboots',
    'Quarterstaff',
    'Rampant Staff',
    'Ravenstrike Cestus',
    'Realmbreaker',
    'Redemption Staff',
    'Rift Glaive',
    'Robe of Purity',
    'Rootbound Staff',
    'Roving Bastion',
    'Royal Armor',
    'Royal Boots',
    'Royal Cowl',
    'Royal Helmet',
    'Royal Hood',
    'Royal Jacket',
    'Royal Robe',
    'Royal Sandals',
    'Sacred Scepter',
    'Sandals of Purity',
    'Sarcophagus',
    'Scholar Cowl',
    'Scholar Robe',
    'Scholar Sandals',
    'Shadowcaller',
    'Shield',
    'Siegebow',
    'Skinner Backpack',
    'Skinner Cap',
    'Skinner Garb',
    'Skinner Workboots',
    'Soldier Armor',
    'Soldier Boots',
    'Soldier Helmet',
    'Soulscythe',
    'Spear',
    'Specter Hood',
    'Specter Jacket',
    'Specter Shoes',
    'Spiked Gauntlets',
    'Spirithunter',
    'Staff of Balance',
    'Stalker Hood',
    'Stalker Jacket',
    'Stalker Shoes',
    'Taproot',
    'Thetford Cape',
    'Tombhammer',
    'Tome of Spells',
    'Torch',
    'Tower Chariot',
    'Trinity Spear',
    'Undead Cape',
    'Ursine Maulers',
    'Wailing Bow',
    'Warbow',
    'Weeping Repeater',
    'Whispering Bow',
    'Wildfire Staff',
    'Wild Staff',
    'Witchwork Staff',
  ];
  
  export default imageList;
  